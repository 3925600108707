<template>
    <div class="logisticsMap">
        <div id="map-chart" class="baiduMap"></div>
    </div>
</template>
<script>
import largeScreenFun from '../../JS';
import mapSkin from '../../JS/skin.js';
import echarts from 'echarts';
require('echarts/extension/bmap/bmap');
export default {
    name: 'logistics-map',
    components: {},
    props: {
        mapData: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            ak: '7FR14186sYk3MqjP9ggWxuHx1AQkRzZX',
            BMap: null,

            time: '',
            stationId: '',
            tenantId: '',

            mapSeries: [],
            mapCenter: [],
            stationLocation: {},
            siteLocation: [],

            mapChart: null,
        };
    },
    created() {},
    mounted() {
        this.$nextTick(function () {
            largeScreenFun.MP(this.ak).then(BMap => {
                this.BMap = BMap;
            });
            const dom = document.getElementById('map-chart');
            this.mapChart = echarts.init(dom);
        });
    },
    watch: {},
    methods: {
        // 初始化地图
        async initMap() {
            this.mapSeries = [];
            this.mapCenter = [];
            this.time = this.$parent.time;
            this.stationId = this.$parent.stationId;
            this.tenantId = this.$parent.tenantId;
            this.logisticsLoading();
        },
        handleMapData() {
            this.mapCenter = [115.490676, 35.251987];
            this.mapSeries = [];
            this.vehicleMarkingLoading();
            this.logisticsChartsLoading();
        },

        // 加载工地标记
        siteTaLloading(newData) {
            let symbolIcon = '';
            let symbolColor = '';
            if (newData.length > 0) {
                this.mapCenter = [newData[0].project_longitude, newData[0].project_latitude];
                newData.forEach(item => {
                    if (item.status === 1) {
                        symbolColor = '#0048B8';
                        symbolIcon = `image://${require('../../images/icon6.png')}`;
                    } else if (item.status === 3) {
                        symbolColor = '#21BC9A';
                        symbolIcon = `image://${require('../../images/icon5.png')}`;
                    } else {
                        symbolColor = '#FF6E77';
                        symbolIcon = `image://${require('../../images/icon7.png')}`;
                    }
                    this.mapSeries.push(
                        {
                            type: 'scatter',
                            coordinateSystem: 'bmap',
                            data: [{
                                name: item.project_name,
                                value: [item.project_longitude, item.project_latitude, 1],
                            }],
                            label: {
                                show: false,
                            },
                            symbol: symbolIcon,
                            symbolSize: 32,
                            symbolOffset: ['6%', '-40%'],
                            itemStyle: {
                                normal: {
                                    color: '#ea6347',
                                    borderWidth: 2,
                                    borderColor: '#f4b391',
                                },
                            },
                            zlevel: 2,
                        },
                        {
                            tooltip: {
                                show: false,
                            },
                            type: 'effectScatter',
                            coordinateSystem: 'bmap',
                            rippleEffect: {
                                scale: 10,
                                brushType: 'stroke',
                            },
                            showEffectOn: 'render',
                            label: {
                                formatter: '{b}',
                                position: 'bottom',
                                color: symbolColor,
                                fontSize: 12,
                                distance: 10,
                                show: true,
                            },
                            itemStyle: {
                                normal: {
                                    shadowColor: '#0ff',
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0,
                                    color: symbolColor,
                                },
                            },
                            symbol: 'circle',
                            symbolSize: [10, 5],
                            data: [{
                                name: item.project_name,
                                value: [item.project_longitude, item.project_latitude, 1],
                            }],
                            zlevel: 1,
                        },
                    );
                });
            } else {
                this.mapCenter = [116.404, 39.915];
            }
        },
        // 车辆标记加载
        vehicleMarkingLoading() {
            let symbolIcon = '';
            let symbolColor = '';
            if (this.mapData.vehicleData.length > 0) {
                this.mapData.vehicleData.forEach(item => {
                    if ([6].indexOf(item.status) > -1) {
                        symbolColor = 'rgba(21, 188, 100, 0.3)';
                        symbolIcon = `image://${require('../../images/green.png')}`;
                    } else if ([77, 8].indexOf(item.status) > -1) {
                        symbolColor = 'rgba(255, 126, 0, 0.3)';
                        symbolIcon = `image://${require('../../images/yellow.png')}`;
                    } else if ([7, 73].indexOf(item.status) > -1) {
                        symbolColor = 'rgba(18, 77, 110, 0.3)';
                        symbolIcon = `image://${require('../../images/blue.png')}`;
                    } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                        symbolColor = 'rgba(255, 110, 119, 0.3)';
                        symbolIcon = `image://${require('../../images/red.png')}`;
                    } else {
                        symbolColor = 'rgba(21, 188, 100, 0.3)';
                        symbolIcon = `image://${require('../../images/green.png')}`;
                    }
                    this.mapSeries.push(
                        {
                            type: 'scatter',
                            coordinateSystem: 'bmap',
                            data: [{
                                name: item.plate_number,
                                value: [item.longitude, item.latitude, 2],
                            }],
                            label: {
                                show: false,
                            },
                            symbol: symbolIcon,
                            symbolRotate: item.direction, // 车辆标记旋转角度
                            symbolSize: [20, 38],
                            // symbolKeepAspect: true,
                            itemStyle: {
                                normal: {
                                    color: '#ea6347',
                                    borderWidth: 2,
                                    borderColor: '#f4b391',
                                },
                            },
                            zlevel: 2,
                        },
                        // {
                        //     tooltip: {
                        //         show: false,
                        //     },
                        //     type: 'effectScatter',
                        //     coordinateSystem: 'bmap',
                        //     data: [ {
                        //         name: item.plate_number,
                        //         value: [item.longitude, item.latitude, 2],
                        //     }],
                        //     symbolSize: 36,
                        //     showEffectOn: 'render',
                        //     rippleEffect: {
                        //         brushType: 'stroke',
                        //     },
                        //     hoverAnimation: true,
                        //     label: {
                        //         formatter: '{b}',
                        //         position: 'bottom',
                        //         color: symbolColor,
                        //         fontSize: 12,
                        //         distance: 10,
                        //         show: true,
                        //     },
                        //     itemStyle: {
                        //         shadowBlur: 8,
                        //         shadowColor: symbolColor,
                        //         color: symbolColor,
                        //     },
                        //     zlevel: 1,
                        // },
                    );
                });
            }
        },
        // 物流加载
        logisticsChartsLoading() {
            // 飞线图标
            // eslint-disable-next-line max-len
            const planePath = 'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z';
            const flyingLineData = [];
            const siteData = [];

            this.mapData.siteLocation.forEach(item => {
                siteData.push({
                    type: 'scatter',
                    coordinateSystem: 'bmap',
                    data: [{
                        name: item.project_name,
                        value: [item.project_longitude, item.project_latitude, 1],
                    }],
                    label: {
                        show: false,
                    },
                    symbol: `image://${require('../../images/icon5.png')}`,
                    symbolSize: 28,
                    symbolOffset: ['6%', '-40%'],
                    itemStyle: {
                        normal: {
                            color: '#ea6347',
                            borderWidth: 2,
                            borderColor: '#f4b391',
                        },
                    },
                    zlevel: 3,
                }, {
                    tooltip: {
                        show: false,
                    },
                    type: 'effectScatter',
                    coordinateSystem: 'bmap',
                    rippleEffect: {
                        scale: 10,
                        brushType: 'stroke',
                    },
                    showEffectOn: 'render',
                    itemStyle: {
                        normal: {
                            shadowColor: '#0ff',
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            color: '#21BC9A',
                        },
                    },
                    label: {
                        formatter: '{b}',
                        position: 'bottom',
                        color: '#01BE86',
                        fontSize: 12,
                        distance: 10,
                        show: true,
                    },
                    symbol: 'circle',
                    symbolSize: [10, 5],
                    data: [{
                        name: item.project_name,
                        value: [item.project_longitude, item.project_latitude, 1],
                    }],
                    zlevel: 2,
                });
                for (const station of this.mapData.stationLocation) {
                    if (station.station_id === item.station_id) {
                        flyingLineData.push({
                            fromName: station.name,
                            toName: item.project_name,
                            coords: [
                                [station.longitude, station.latitude],
                                [item.project_longitude, item.project_latitude],
                            ],
                        });
                        break;
                    }
                }
            });
            const stationData = this.mapData.stationLocation.map(station => {
                return {
                    name: station.name,
                    value: [station.longitude, station.latitude, 500],
                };
            });
            this.mapSeries.push(...siteData,
                // {
                //     type: 'lines',
                //     coordinateSystem: 'bmap',
                //     zlevel: 2,
                //     effect: {
                //         show: false,
                //         period: 6,
                //         trailLength: 0,
                //         symbol: planePath,
                //         symbolSize: 15,
                //     },
                //     lineStyle: {
                //         normal: {
                //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                //                 offset: 0,
                //                 color: 'rgba(252, 239, 32, 0)',
                //             }, {
                //                 offset: 1,
                //                 color: 'rgba(254, 189, 41, 1)',
                //             }]),
                //             width: 2,
                //             opacity: 1,
                //             curveness: 0.2,
                //         },
                //     },
                //     data: flyingLineData,
                // },
                {
                    type: 'scatter',
                    coordinateSystem: 'bmap',
                    data: stationData,
                    symbol: `image://${require('../../images/icon22.png')}`,
                    symbolSize: 80,
                    itemStyle: {
                        normal: {
                            color: '#ea6347',
                            borderWidth: 2,
                            borderColor: '#f4b391',
                        },
                    },
                    label: {
                        formatter: '{b}',
                        position: 'bottom',
                        color: '#FEBD29',
                        fontSize: 10,
                        distance: -16,
                        show: true,
                    },
                    zlevel: 4,
                });
        },
        initChart() {
            const _that = this;
            this.time = this.$parent.time;
            const option = {
                bmap: {
                    center: this.mapCenter,
                    zoom: 11,
                    roam: true,
                    mapStyleV2: {
                        styleJson: mapSkin,
                    },
                },
                series: this.mapSeries,
            };

            this.mapChart.setOption(option, true);
            const bmap = this.mapChart.getModel().getComponent('bmap').getBMap();


            this.mapChart.on('click', async function (params) {
                let newContent = '';
                let vehicleInfo = {};
                let opts = {};
                _that.$parent.loading = true;
                if (params.value[2] === 1) { // 工地标记点击
                    const projectCodeArr = _that.mapData.siteLocation.filter(item => item.project_name === params.name) || [];
                    let projectCode = '';
                    if (projectCodeArr.length > 0) {
                        projectCode = projectCodeArr[0].project_code;
                    }
                    vehicleInfo = await largeScreenFun.getConstructionSiteInfo(_that.time, projectCode, _that.stationId, _that.tenantId);
                } if (params.value[2] === 2) { // 车辆标记点击
                    const plate_number = params.name;
                    vehicleInfo = await largeScreenFun.getVehicleInfo(_that.time, plate_number, _that.stationId, _that.tenantId);
                }
                if (Object.keys(vehicleInfo).length > 0) {
                    if (params.value[2] === 1) { // 工地标记点击
                        newContent = _that.loadConstructionSitePop(vehicleInfo);
                        opts = {
                            width: 350,
                            height: 350,
                        };
                    } if (params.value[2] === 2) { // 车辆标记点击
                        newContent = _that.loadVehiclePop(vehicleInfo);
                        opts = {
                            width: 350,
                            height: 230,
                        };
                    }
                    const infoWindow = new _that.BMap.InfoWindow(newContent, opts);
                    const point = new _that.BMap.Point(params.value[0], params.value[1]);
                    bmap.openInfoWindow(infoWindow, point); // 开启信息窗口
                } else {
                    _that.$message({
                        message: '暂无详细信息',
                        type: 'warning',
                    });
                }
                _that.$parent.loading = false;
            });
        },
        // 加载车辆Pop
        loadVehiclePop(vehicleInfo) {
            let newContent = '';
            newContent += '<ul class="mapPopStyle vehiclePop">';
            newContent += '<li class="vehiclePopHeader">';
            newContent += `<span class="licenseNumber iconfont iconTmpSVG">${vehicleInfo.plate_number}</span>`;
            newContent += `<span class="carSmall">${vehicleInfo.plate_small_number}</span>`;
            if ([6, 7, 73].indexOf(vehicleInfo.status) > -1) {
                newContent += '<span class="vehicleStatus vehicleStatus_ys">运输中</span>';
            } else if ([77, 8].indexOf(vehicleInfo.status) > -1) {
                newContent += '<span class="vehicleStatus vehicleStatus_fc">返场中</span>';
            } else {
                newContent += '<span class="vehicleStatus vehicleStatus_wys">未运输</span>';
            }
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont icondriver">司机姓名：${vehicleInfo.sjxm}</p>`;
            newContent += `<p class="iconfont icondianhua">电话：${vehicleInfo.sjsjh}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconlifang">方量：${vehicleInfo.concrete_volume} 方</p>`;
            newContent += `<p class="iconfont iconorder">发货单号：${vehicleInfo.pcb_number}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconchengshi">工地名称：${vehicleInfo.project_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconcishu1">今日运输次数：${vehicleInfo.runcount} 次</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconshijian">到达工地时间：${this.SecondToDate(vehicleInfo.arrival_time)}</p>`;
            newContent += '</li>';
            newContent += '</ul>';
            return newContent;
        },

        // 加载工地Pop
        loadConstructionSitePop(vehicleInfo) {
            let newContent = '';
            newContent += '<ul class="mapPopStyle constructionSitePop">';
            newContent += '<li>';
            newContent += `<p class="iconfont icondriver">工程名称：${vehicleInfo.project_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconorder">工程编号：${vehicleInfo.project_code}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconchengshi">建设单位：${vehicleInfo.sgdw_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconcishu1">工程类型：${vehicleInfo.project_type_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconshijian">工程位置：${vehicleInfo.project_address}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconshijian">供货场站：${vehicleInfo.station_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconshijian">当日订单方量：${vehicleInfo.drordercount} 方</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconshijian">当日已供方量：${vehicleInfo.drycount} 方</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconshijian">累计已供方量：${vehicleInfo.ordersum} 方</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconshijian">合同数：${vehicleInfo.contractcount}</p>`;
            newContent += '</li>';
            newContent += '</ul>';
            return newContent;

        },

        // 秒转天
        SecondToDate(msd) {
            let time = msd;
            if (msd) {
                if (msd > 3600) {
                    time = '一小时以上';
                } else if (msd <= 3600 && msd >= 60 ) {
                    time = '大约 ' + parseInt(time / 60.0, 0) + '分钟 以后';
                } else {
                    time = '一分钟以内';
                }
            } else {
                msd = '';
            }
            return time;
        },
    },
};
</script>
<style scoped></style>
